import Script from 'next/script';
import { getDateFormatZulu } from "@/helpers/formatDate";

const LdjsonNewsArticle = ({ dataNews }) => { 

  video: Array.isArray(dataNews.relatedVideos) ? dataNews.relatedVideos[0] : dataNews.relatedVideos

  const opinion = dataNews.url.toLowerCase().includes("opinion")

  const isNormalType = dataNews.type === "normal";
  const shouldSkip =
    isNormalType &&
    !dataNews.url.toLowerCase().includes("/recetas/") &&
    !dataNews.url.toLowerCase().includes("/fotogalerias/") &&
    !((dataNews?.sections || []).some(section => section.name.toLowerCase() === "videos"));

  // let speakable = "";  
  
  // if (shouldSkip) {
  //   speakable = {
  //     "@type": "SpeakableSpecification",
  //     "xPath": [
  //       "/html/head/title",
  //       "/html/head/meta[@name='description']/@content"
  //     ],
  //     "cssSelector": [
  //       ".vsmcontent p"
  //     ]
  //   }
  // } 


  const description = dataNews.summary ? dataNews.summary.replace(/<[^>]*>?/g, "").replace(/'/g, '') : "";
  let url;

  if (dataNews.relatedVideos && dataNews.relatedVideos.thumbnail) {
    ({ thumbnail: url = "" } = dataNews.relatedVideos);
  } else {
    ({ related_image_url: url = "" } = dataNews);
  }
  
  const [width, height] = url.match(/\d+x\d+/)?.[0]?.split('x').map(Number) || [320, 180];  
  let dateModified;
  try {
    dateModified = dataNews?.lastModified;
  } catch (e) {
    dateModified = new Date().toISOString(); 
  }

  const parseDesc = description
    ? dataNews.summary.replace(/<[^>]*>?/g, "").replace(/'/g, '')
    : "";
    
  const name = dataNews.relatedVideos ? dataNews.relatedVideos.name : "";
  const thumbnail = dataNews.relatedVideos
    ? dataNews.relatedVideos.thumbnail
    : "";
  const contentUrl = dataNews.relatedVideos
    ? dataNews.relatedVideos.videoFiles.mp4
    : "";
    const embedUrl = dataNews.relatedVideos
    ? dataNews.relatedVideos.entityId
    : "";

  //console.log("VIDEOS", dataNews.relatedVideos)  
    
  let dataVideo = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    description: parseDesc,
    name: name,
    contentUrl: contentUrl,
    embedUrl: `https://cdn.jwplayer.com/players/${embedUrl}.html`,
    uploadDate: dataNews.publishedDate?.substring(0, dataNews.publishedDate.length - 5) +
    "-00:00",
    thumbnailUrl: thumbnail,
  };

  if(dataNews?.relatedVideos){

    dataNews?.relatedVideos?.flavors?.forEach((flavor) => {
      if(flavor?._id == "720p"){
        dataVideo["thumbnailUrl"] = (flavor?.thumbnails?.[0]?.url) ?? (flavor?.thumbnail);
      }
    });
    
    let duration = dataNews.relatedVideos?.duration?.split(":");
    if(!!duration && duration.length > 0) {
      duration = duration.map((element) => {
        if(element.length == 2 && element?.startsWith('0')){
          element = element[1];
        }
        return element;
      });
      switch (duration.length) {
        case 3:
          dataVideo["duration"] = `PT${duration[0]}H${duration[1]}M${duration[2]}S`;
          break;
        case 2:
          dataVideo["duration"] = `PT${duration[0]}M${duration[1]}S`;
          break;
      }      
    } 
    if(!contentUrl){
      dataVideo["embedUrl"] = `https://cdn.jwplayer.com/previews/${dataNews.relatedVideos?.entityId}`;
    }
  }

  let articleBody = ""

  const articleBodyAll = dataNews?.body && Array.isArray(dataNews.body) ? dataNews?.body?.filter((element) => (element?.type == "text") ) : dataNews?.body;
  const articleBodyObejct = articleBodyAll && Array.isArray(articleBodyAll) ? articleBodyAll.map((element) => (element.value)) : dataNews?.body ;
  articleBody = articleBodyObejct != undefined ? String(articleBodyObejct).replace(/<[^>]*>?|,/g, "") : "";
  if (dataNews.type === "liveblog" && dataNews.body) {
    const liveBlogItems = dataNews.body.filter((item) => item.type === "liveblog");
  
    if (liveBlogItems.length > 0) {
      const liveBlogBodies = liveBlogItems[0].value.body.filter((item) => item.type === 'text');
      const newsUpdate = liveBlogBodies.map((element) => element.value).join(" ");
      articleBody += newsUpdate;
    }
  }
  
  articleBody = articleBody.replace(/<[^>]*>?|,/g, "");
  
  const isFree = dataNews.accesibleForFree && dataNews.accesibleForFree == "suscribers-only" ? false : true;   

  let dataSchemaNewsArticle = {
    "@context": "http://schema.org",
    "@type":
    opinion ?
      ["NewsArticle", "OpinionNewsArticle"] :
      ["NewsArticle"],
    dateCreated: dataNews.publishedDate ? getDateFormatZulu(dataNews.publishedDate) : "",
    datePublished: dataNews.publishedDate ?  getDateFormatZulu(dataNews.publishedDate) : "",
    dateModified: dateModified ?  getDateFormatZulu(dateModified) : "",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": dataNews.url,
    },
    headline: dataNews.title,
    alternativeHeadline: dataNews.subtitle,
    description: description,
    articleBody: articleBody,
    isAccessibleForFree: isFree,
    keywords: dataNews.keywords,
    citation: {
      "type" : "CreativeWork",
      url: dataNews.url,
    },
    url: dataNews.url,
    image:
    dataNews.relatedVideos
    ? [
      {
        "@context": "http://schema.org",
        "@type": "ImageObject",
        url: dataNews.relatedVideos.thumbnail,
        height: height,
        width: width,
      },
    ] :
    dataNews.related_image_url.length > 5 ?
    [
      {
        "@context": "http://schema.org",
        "@type": "ImageObject",
        url: dataNews.related_image_url,
        height: height,
        width: width,
      },
    ] : [
      {
      "@context": "http://schema.org",
      "@type": "ImageObject",
      url: "https://www.clarin.com/img/clarin-sharing.png",
      height: 600,
      width: 600,
      },
    ],
    articleSection: dataNews?.sections && Array.isArray(dataNews?.sections) ? dataNews?.sections?.map((e) => e.name) : dataNews?.sections,
    author:
    dataNews.authors ?
    dataNews.authors.map((author) => ({
      type: "Person",
      name: author.name,
      url: "https://www.clarin.com/autor/" + author.slug + ".html",
      image: author.imageUrl
            ? (author.imageUrl.includes("http://") 
            ? author.imageUrl.replace("http://", "https://").replace("https://images.clarin.com", "https://www.clarin.com/img")
            : process.env.NEXT_PUBLIC_IMAGE_DOMAIN + author.imageUrl)
            : "https://www.clarin.com/img/favicon-96x96.png",
      email: author.email, 
      jobTitle: author.shortBiography,
      contactPoint: {
        "@type": "ContactPoint",
        contactType: "Periodista",
        email: author.email, 
      }
    })) : 
    {
      type: "Organization",
      name: "Redacción Clarín",
      url: "https://www.clarin.com/",
    },
    publisher: {
      "@type": "Organization",
      name: "Clarín",
      url: "https://www.clarin.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://www.clarin.com/img/logo_clarin.png",
        width: 234,
        height: 60,
      },
      sameAs: [
        "https://www.facebook.com/clarincom/",
        "https://twitter.com/clarincom",
      ],
    },
    isPartOf: {
      "@type": ["CreativeWork", "Product"],
      name: "Clarín",
    },
    hasPart: {
      "@type": "WebPageElement",
      isAccessibleForFree: isFree,
      cssSelector: ".paywall_content",
    },
    // speakable
  }  
  {
    dataNews.relatedVideos ? (dataSchemaNewsArticle.video = dataVideo) : "";
  }
  return (
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(dataSchemaNewsArticle) }}
    />
  );
};

export default LdjsonNewsArticle;
